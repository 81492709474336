import { MdPhotoLibrary } from "react-icons/md"
import { useSelector } from "react-redux"
import { classNames, dateformat } from '@/components/common/Helpers'
import ReactLinkify from "react-linkify"

export default function GuestbookReplyItem({ post, className, setObituaryPost }) {
    const { obituary } = useSelector((state) => state.obituaryReducer)

    return (
        <div className={classNames("overflow-hidden mb-2 text-xs flex", className)}>
            <div className="bg-white rounded-md p-4 border-l-4 border-blue-400 w-full">
                {(post.obituary_assets?.length > 0 && obituary) && (
                    <div className="relative cursor-pointer mb-2" onClick={() => setObituaryPost(post)}>
                        <img
                            loading="lazy"
                            src={post.obituary_assets[0].asset.thumbnail}
                            className="w-full object-cover max-h-80"
                            alt={`Guestbook gallery photo 1: ${obituary.display_name} posted by ${post.name}`}
                        />
                        <div className="absolute bottom-2 right-2 w-12 h-12 bg-black/50 rounded-full text-white flex items-center justify-center">
                            <MdPhotoLibrary className="w-6 h-6" />
                        </div>
                    </div>
                )}

                <div className="mb-2 leading-4">
                    <p className="whitespace-pre-wrap">
                        <ReactLinkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a
                                    href={decoratedHref}
                                    className="text-blue-500 break-all"
                                    target="_blank"
                                    rel="noopener"
                                    key={key}
                                >
                                    {decoratedText}
                                </a>
                            )}
                        >
                            <span dangerouslySetInnerHTML={{ __html: post.formatted_message }} />
                        </ReactLinkify>
                    </p>
                </div>

                <div>
                    <p className="text-gray-800 uppercase font-bold">{post.name}</p>
                    <p className="text-gray-400">{dateformat(post.submitted_at)}</p>
                </div>
            </div>
        </div>
    )
}
